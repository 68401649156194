import AdminPage from '@pages/Admin';
import HomePage from '@pages/Home';
import React, { useMemo } from 'react';
import { GlobalStyle } from './App.style';
import { useTest } from './utils/useTest';

function App() {
  const { hasError, errorMessage } = useTest();

  if (hasError) {
    return <div>{errorMessage}</div>;
  }

  const isAdmin = useMemo<boolean>(() => window.location.hash === '#admin', []);

  return (
    <>
      <GlobalStyle />
      {isAdmin ? <AdminPage /> : <HomePage />}
    </>
  );
}

export default App;
