export type LatLng = { getLat: () => number; getLng: () => number };
export type LatLngBounds = {
  getSouthWest: () => LatLng;
  getNorthEast: () => LatLng;
};
export const INITIAL_KAKAO_MAP_LEVEL = 4;
export const MarkerLimit = 100;

export type RegionDataType = {
  // prettier-ignore
  "경기도": ("연천군" | "포천시" | "용인시" | "안산시" | "양평군" | "성남시" | "평택시" | "양주시" | "하남시" | "용인시" | "광명시" | "남양주시" | "성남시" | "부천시" | "고양시" | "성남시" | "수원시" | "여주시" | "수원시" | "안산시" | "광주시" | "화성시" | "고양시" | "고양시" | "수원시" | "안성시" | "안양시" | "김포시" | "수원시" | "시흥시" | "용인시" | "성남시" | "오산시" | "이천시" | "수원시" | "안산시" | "군포시" | "의왕시" | "안양시" | "구리시" | "안양시" | "파주시" | "과천시" | "가평군" | "고양시" | "의정부시" | "용인시" | "동두천시")[];
  // prettier-ignore
  "충청북도": ("청주시" | "진천군" | "음성군" | "단양군" | "영동군" | "보은군" | "청주시" | "증평군" | "청주시" | "충주시" | "괴산군" | "옥천군" | "청주시" | "제천시" | "청주시")[];
  // prettier-ignore
  "부산광역시": ("사하구" | "수영구" | "해운대구" | "동구" | "북구" | "중구" | "서구" | "영도구" | "부산진구" | "강서구" | "연제구" | "동래구" | "기장군" | "금정구" | "사상구" | "남구")[];
  // prettier-ignore
  "대구광역시": ("수성구" | "동구" | "달서구" | "달성군" | "남구" | "중구" | "북구")[];
  // prettier-ignore
  "강원도": ("철원군" | "양구군" | "양양군" | "횡성군" | "동해시" | "춘천시" | "화천군" | "태백시" | "평창군" | "속초시" | "정선군" | "영월군" | "강릉시" | "고성군" | "원주시" | "삼척시" | "홍천군" | "인제군")[];
  // prettier-ignore
  "전라북도": ("남원시" | "김제시" | "전주시" | "장수군" | "완주군" | "무주군" | "부안군" | "진안군" | "정읍시" | "전주시" | "군산시" | "익산시" | "순창군" | "고창군" | "임실군" | "전주시")[];
  // prettier-ignore
  "경상남도": ("고성군" | "창원시" | "창원시" | "산청군" | "진주시" | "창녕군" | "거창군" | "밀양시" | "창원시" | "창원시" | "의령군" | "남해군" | "창원시" | "사천시" | "김해시" | "함안군" | "창원시" | "거제시" | "창원시" | "창원시" | "하동군" | "함양군" | "통영시" | "합천군" | "양산시")[];
  // prettier-ignore
  "충청남도": ("서산시" | "아산시" | "보령시" | "청양군" | "천안시" | "부여군" | "홍성군" | "태안군" | "논산시" | "서천군" | "당진시" | "천안시" | "계룡시" | "천안시" | "공주시" | "금산군" | "예산군")[];
  // prettier-ignore
  "전라남도": ("함평군" | "광양시" | "신안군" | "장흥군" | "완도군" | "진도군" | "영암군" | "해남군" | "영광군" | "장성군" | "담양군" | "고흥군" | "나주시" | "목포시" | "보성군" | "곡성군" | "여수시" | "강진군" | "순천시" | "화순군" | "무안군" | "구례군")[];
  // prettier-ignore
  "경상북도": ("군위군" | "영양군" | "영주시" | "김천시" | "포항시" | "구미시" | "영덕군" | "문경시" | "고령군" | "포항시" | "경산시" | "경주시" | "예천군" | "안동시" | "칠곡군" | "청도군" | "의성군" | "울진군" | "포항시" | "영천시" | "포항시" | "성주군" | "봉화군" | "울릉군" | "청송군" | "상주시")[];
  // prettier-ignore
  "인천광역시": ("연수구" | "서구" | "남동구" | "강화군" | "옹진군" | "동구" | "미추홀구" | "계양구" | "부평구" | "중구")[];
  // prettier-ignore
  "대전광역시": ("동구" | "유성구" | "서구" | "대덕구" | "중구")[];
  // prettier-ignore
  "광주광역시": ("북구" | "남구" | "서구" | "광산구" | "동구")[];
  // prettier-ignore
  "제주특별자치도": ("제주시" | "서귀포시")[];
  // prettier-ignore
  "서울특별시": ("은평구" | "성동구" | "금천구" | "서초구" | "양천구" | "용산구" | "동작구" | "종로구" | "도봉구" | "중구" | "마포구" | "구로구" | "서대문구" | "강동구" | "동대문구" | "영등포구" | "광진구" | "강남구" | "관악구" | "송파구" | "강서구" | "노원구" | "중랑구" | "강북구" | "성북구")[];
  // prettier-ignore
  "울산광역시": ("동구" | "중구" | "북구" | "남구" | "울주군")[];
};

export const RegionData: RegionDataType = {
  // prettier-ignore
  "경기도": ["연천군", "포천시", "용인시", "안산시", "양평군", "성남시", "평택시", "양주시", "하남시", "용인시", "광명시", "남양주시", "성남시", "부천시", "고양시", "성남시", "수원시", "여주시", "수원시", "안산시", "광주시", "화성시", "고양시", "고양시", "수원시", "안성시", "안양시", "김포시", "수원시", "시흥시", "용인시", "성남시", "오산시", "이천시", "수원시", "안산시", "군포시", "의왕시", "안양시", "구리시", "안양시", "파주시", "과천시", "가평군", "고양시", "의정부시", "용인시", "동두천시"],
  // prettier-ignore
  "충청북도": ["청주시", "진천군", "음성군", "단양군", "영동군", "보은군", "청주시", "증평군", "청주시", "충주시", "괴산군", "옥천군", "청주시", "제천시", "청주시"],
  // prettier-ignore
  "부산광역시": ["사하구", "수영구", "해운대구", "동구", "북구", "중구", "서구", "영도구", "부산진구", "강서구", "연제구", "동래구", "기장군", "금정구", "사상구", "남구"],
  // prettier-ignore
  "대구광역시": ["수성구", "동구", "달서구", "달성군", "남구", "중구", "북구"],
  // prettier-ignore
  "강원도": ["철원군", "양구군", "양양군", "횡성군", "동해시", "춘천시", "화천군", "태백시", "평창군", "속초시", "정선군", "영월군", "강릉시", "고성군", "원주시", "삼척시", "홍천군", "인제군"],
  // prettier-ignore
  "전라북도": ["남원시", "김제시", "전주시", "장수군", "완주군", "무주군", "부안군", "진안군", "정읍시", "전주시", "군산시", "익산시", "순창군", "고창군", "임실군", "전주시"],
  // prettier-ignore
  "경상남도": ["고성군", "창원시", "창원시", "산청군", "진주시", "창녕군", "거창군", "밀양시", "창원시", "창원시", "의령군", "남해군", "창원시", "사천시", "김해시", "함안군", "창원시", "거제시", "창원시", "창원시", "하동군", "함양군", "통영시", "합천군", "양산시"],
  // prettier-ignore
  "충청남도": ["서산시", "아산시", "보령시", "청양군", "천안시", "부여군", "홍성군", "태안군", "논산시", "서천군", "당진시", "천안시", "계룡시", "천안시", "공주시", "금산군", "예산군"],
  // prettier-ignore
  "전라남도": ["함평군", "광양시", "신안군", "장흥군", "완도군", "진도군", "영암군", "해남군", "영광군", "장성군", "담양군", "고흥군", "나주시", "목포시", "보성군", "곡성군", "여수시", "강진군", "순천시", "화순군", "무안군", "구례군"],
  // prettier-ignore
  "경상북도": ["군위군", "영양군", "영주시", "김천시", "포항시", "구미시", "영덕군", "문경시", "고령군", "포항시", "경산시", "경주시", "예천군", "안동시", "칠곡군", "청도군", "의성군", "울진군", "포항시", "영천시", "포항시", "성주군", "봉화군", "울릉군", "청송군", "상주시"],
  // prettier-ignore
  "인천광역시": ["연수구", "서구", "남동구", "강화군", "옹진군", "동구", "미추홀구", "계양구", "부평구", "중구"],
  // prettier-ignore
  "대전광역시": ["동구", "유성구", "서구", "대덕구", "중구"],
  // prettier-ignore
  "광주광역시": ["북구", "남구", "서구", "광산구", "동구"],
  // prettier-ignore
  "제주특별자치도": ["제주시", "서귀포시"],
  // prettier-ignore
  "서울특별시": ["은평구", "성동구", "금천구", "서초구", "양천구", "용산구", "동작구", "종로구", "도봉구", "중구", "마포구", "구로구", "서대문구", "강동구", "동대문구", "영등포구", "광진구", "강남구", "관악구", "송파구", "강서구", "노원구", "중랑구", "강북구", "성북구"],
  // prettier-ignore
  "울산광역시": ["동구", "중구", "북구", "남구", "울주군"],
};
