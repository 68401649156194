import { MainColor, SubColor } from '@utils/color';
import styled, { css } from 'styled-components';

export const Banner = styled.div`
  width: 100vw;
  height: 140px;
  background: url('/banner_1400.jpg') no-repeat center center #f9f8fd;
  background-size: contain;

  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  z-index: 9999;

  @media (min-width: 1200px) {
    background-image: url('/banner_1200.jpg');
  }
  @media (max-width: 992px) {
    background-image: url('/banner_992.jpg');
  }
  @media (max-width: 768px) {
    height: 120px;
    background-image: url('/banner_768.jpg');
  }
  @media (max-width: 576px) {
    background-image: url('/banner_576.jpg');
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 90vh;
  @media (max-width: 767px) {
    height: 85vh;
  }
`;

export const Map = styled.div<{ panelHeight: number }>`
  width: 100%;
  height: 100%;
  @media (max-width: 767px) {
    height: calc(100% - ${({ panelHeight }) => panelHeight}px);
  }
`;

export const SearchPanel = {
  Container: styled.div`
    position: absolute;
    top: 16px;
    left: 16px;
    bottom: 16px;
    z-index: 10;
    width: 300px;
    background-color: #fff;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.05);
    border: 1px solid #ccc;
    overflow: hidden;

    @media (max-width: 767px) {
      position: static;
      top: 0;
      left: 0;
      right: 0;
      bottom: auto;
      width: 100%;
      border-radius: 0px;
    }
  `,
  Tabs: styled.nav`
    width: 100%;
    margin-bottom: 50px;
  `,
  TabButton: styled.a<{ selected: boolean }>`
    display: table-cell;
    vertical-align: middle;
    float: left;
    width: calc(50% - 8px);
    margin: 0px 4px;
    height: 40px;
    line-height: 40px;
    border-radius: 5px;
    background-color: ${({ selected }) =>
      selected ? MainColor[700] : css`#fff`};
    color: ${({ selected }) => (selected ? css`#fff` : SubColor[600])};
    text-decoration: none;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      background-color: ${({ selected = false }) =>
        selected ? MainColor[800] : SubColor[100]};
    }
  `,
};

export const CurrentLocation = styled.a`
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 1000;
  border-radius: 4px;
  padding: 8px;
  background-color: white;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  & > img {
    margin: 0 auto;
    display: block;
    width: 36px;
    height: 36px;
  }
  & > .button-text {
    margin-top: 4px;
    font-weight: bold;
  }
  &:hover {
    background-color: ${SubColor[100]};
  }
`;

export const LoadingCircle = styled.div`
  border-radius: 50%;
  width: 36px;
  height: 36px;
  border: 4px solid #cfcfcf;
  border-top: 4px solid #3498db;
  animation: spin 1s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
