import { API } from 'aws-amplify';
import axios from 'axios';
import { SearchKeywordOptions, SearchKeywordResult } from './api.type';
import { LatLng, LatLngBounds, MarkerLimit } from './constant';

export type LingteaData = {
  name: string;
  lat: string;
  lng: string;
  address: string;
  phone: string;
  distance?: number;
};

function csvToArray(text: string): string[] {
  const matches = text.match(/(\s*"[^"]+"\s*|\s*[^,]+|,)(?=,|$)/g);
  if (matches === null) {
    return [];
  }
  for (let n = 0; n < matches.length; n += 1) {
    matches[n] = matches[n].trim();
    if (matches[n] === ',') matches[n] = '';
  }
  if (text[0] === ',') matches.unshift('');
  return matches;
}

// 링티 약국 데이터 가져오기
export const getLingteaData = async (from?: string) => {
  const data = from ?? (await API.get('lingteaMaps', '/pharmacies', {}));

  const processString = (str?: string) => {
    if (!str) return '';
    if (str.charAt(0) === '"' && str.charAt(str.length - 1) === '"')
      return str.substr(1, str.length - 2);
    return str;
  };

  if (typeof data !== 'string')
    throw new Error('입력 받은 데이터형이 string 타입이 아닙니다.');
  if (data.trim().split('\n').length < 2)
    throw new Error('데이터가 하나도 없습니다.');
  const jsonData: LingteaData[] = (data as string)
    .trim()
    .split('\n')
    .slice(1)
    .map((row, index) => {
      const columns = csvToArray(row) ?? [];
      if (columns.length < 5)
        throw new Error(`${index + 1}번째 행 데이터가 부족합니다. "${row}"`);
      return {
        name: processString(columns?.[0] ?? ''),
        lat: processString(columns?.[1] ?? ''),
        lng: processString(columns?.[2] ?? ''),
        address: processString(columns?.[3] ?? ''),
        phone: processString(columns?.[4] ?? ''),
      };
    });

  const uniqueData: LingteaData[] = [];
  jsonData.forEach((item) => {
    if (
      uniqueData.findIndex(
        ({ name, lat, lng }) =>
          name === item.name && lat === item.lat && lng === item.lng,
      ) === -1
    ) {
      uniqueData.push(item);
    }
  });

  return uniqueData;
};

// 두 지점 거리를 km로 계산
export const calculateDistance = (
  lat1: number,
  lng1: number,
  lat2: number,
  lng2: number,
) => {
  const R = 6371; // Radius of the earth in km
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLng = ((lng2 - lng1) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
};

// 약국데이터, 가까운 거리 순으로 정렬 후 추려 가져오기
export const calculateNearestLingteaData = (
  data: LingteaData[],
  center: LatLng,
  bounds?: LatLngBounds,
) => {
  const nearestData: Required<LingteaData>[] = [];

  // 화면 밖 부분은 제외하고 거리 계산
  data.forEach((datum) => {
    if (bounds) {
      const northEast = bounds.getNorthEast();
      const southWest = bounds.getSouthWest();
      if (
        +datum.lat < southWest.getLat() ||
        +datum.lat > northEast.getLat() ||
        +datum.lng < southWest.getLng() ||
        +datum.lng > northEast.getLng()
      ) {
        return;
      }
    }
    const distance = calculateDistance(
      center.getLat(),
      center.getLng(),
      +datum.lat,
      +datum.lng,
    );
    nearestData.push({
      ...datum,
      distance,
    });
  });

  // 정렬
  nearestData.sort((a, b) => {
    if (a.distance < b.distance) return -1;
    if (a.distance > b.distance) return 1;
    return 0;
  });

  const result: Required<LingteaData>[] = nearestData.slice(0, MarkerLimit);
  return result;
};

const kakaoApi = axios.create({
  baseURL: 'https://dapi.kakao.com/',
  timeout: 5000,
  headers: {
    Authorization: `KakaoAK ${process.env.REACT_APP_KAKAO_REST_API_KEY}`,
  },
});

export const searchKeyword = (
  query: string,
  options?: SearchKeywordOptions,
) => {
  return kakaoApi.get<SearchKeywordResult>(`/v2/local/search/keyword.json`, {
    params: {
      query,
      ...options,
    },
  });
};
