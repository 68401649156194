import { kakao } from '@pages/Home/index.kakaomap';
import { useEffect, useState } from 'react';

export const useTest = () => {
  const initState = {
    hasError: false,
    errorMessage: '',
  };
  const [state, setState] = useState(initState);

  useEffect(() => {
    if (typeof process.env.REACT_APP_KAKAO_APP_KEY !== 'string') {
      setState({
        hasError: true,
        errorMessage: 'KAKAO API KEY값이 존재하지 않습니다.',
      });
    } else if (!kakao) {
      setState({
        hasError: true,
        errorMessage: 'KAKAO SDK가 로드되지 않았습니다.',
      });
    }
  }, [kakao]);

  return state as typeof initState;
};
