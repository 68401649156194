/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-2",
    "aws_cloud_logic_custom": [
        {
            "name": "lingteaMaps",
            "endpoint": "https://lpvjr4n96j.execute-api.ap-northeast-2.amazonaws.com/dev",
            "region": "ap-northeast-2"
        }
    ]
};


export default awsmobile;
