import RegionTab from '@components/RegionTab';
import SearchTab from '@components/SearchTab';
import { getLingteaData } from '@utils/api';
import { isIE } from '@utils/environment';
import React, { useCallback, useEffect, useState } from 'react';
import { useKakaoMap } from './index.hook';
import {
  Banner,
  Container,
  CurrentLocation,
  LoadingCircle,
  Map,
  SearchPanel,
} from './index.style';

const HomePage = () => {
  const [selectedTab, setSelectedTab] = useState<'region' | 'search'>('region');
  const [gpsLoading, setGpsLoading] = useState(false);
  const {
    mapRef,
    lingteaData,
    displayingLingteaData,
    selectedLingteaData,
    setLingteaData,
    setSelectedLingteaData,
    setMapCenter,
    setRegion,
    setBuildingMarker,
  } = useKakaoMap();

  // 초기 지도데이터 불러오기
  useEffect(() => {
    getLingteaData().then((data) => {
      setLingteaData(data);
    });
  }, []);

  const [panelHeight, setPanelHeight] = useState<number>(0);
  const panelRef = useCallback<React.RefCallback<HTMLDivElement>>(
    ($el: HTMLDivElement) => {
      if (!$el || isIE) return;
      new ResizeObserver(() => {
        setPanelHeight($el?.offsetHeight ?? 0);
      }).observe($el);
    },
    [],
  );

  // JS 콜백 이벤트
  useEffect(() => {
    type PhamarcyMouseEventParams = {
      lat: number;
      lng: number;
      name: string;
      address: string;
      phone: string;
    };
    const getPharmacy = (params: PhamarcyMouseEventParams) =>
      lingteaData.find(
        (each) =>
          each.name === params.name &&
          each.address === params.address &&
          each.phone === params.phone &&
          +each.lat === params.lat &&
          +each.lng === params.lng,
      );
    // 커스텀오버레이 MouseClick
    (window as any).markerClick = (
      name: string,
      lat: number,
      lng: number,
      address: string,
      phone: string,
    ) => {
      const targetPharmacy = getPharmacy({
        name,
        lat,
        lng,
        address,
        phone,
      });
      setSelectedLingteaData(targetPharmacy);
    };
  }, [lingteaData, setSelectedLingteaData]);

  const moveToCurrentLocation = useCallback(() => {
    if (!navigator.geolocation) return;
    setGpsLoading(true);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setGpsLoading(false);
        const { latitude: lat, longitude: lng } = position.coords as any;
        setMapCenter(lat, lng);
        setBuildingMarker(lat, lng);
        setSelectedLingteaData(undefined);
      },
      () => {
        alert('위치정보를 찾을 수 없습니다.');
      },
    );
  }, [setMapCenter, setBuildingMarker, setSelectedLingteaData]);

  return (
    <>
      <Banner />
      <Container>
        <SearchPanel.Container ref={panelRef}>
          <SearchPanel.Tabs>
            <SearchPanel.TabButton
              onClick={() => setSelectedTab('region')}
              selected={selectedTab === 'region'}
            >
              시군구 검색
            </SearchPanel.TabButton>
            <SearchPanel.TabButton
              onClick={() => setSelectedTab('search')}
              selected={selectedTab === 'search'}
            >
              주소 검색
            </SearchPanel.TabButton>
          </SearchPanel.Tabs>

          {selectedTab === 'region' && (
            <RegionTab
              panelHeight={panelHeight}
              setRegion={setRegion}
              setMapCenter={setMapCenter}
              displayingData={displayingLingteaData}
              selectedLingteaData={selectedLingteaData}
              setSelectedLingteaData={setSelectedLingteaData}
            />
          )}

          {selectedTab === 'search' && (
            <SearchTab
              panelHeight={panelHeight}
              setMapCenter={setMapCenter}
              setBuildingMarker={setBuildingMarker}
            />
          )}
        </SearchPanel.Container>
        {navigator.geolocation && (
          <CurrentLocation onClick={moveToCurrentLocation}>
            {gpsLoading ? (
              <LoadingCircle />
            ) : (
              <img src="/current_location.png" alt="내 GPS 위치로 이동" />
            )}
            <div className="button-text">현위치</div>
          </CurrentLocation>
        )}
        <Map panelHeight={panelHeight} id="map" ref={mapRef} />
      </Container>
    </>
  );
};

export default HomePage;
