import { MainColor, SubColor } from '@utils/color';
import { isMobile } from '@utils/environment';
import { createGlobalStyle, css } from 'styled-components';
import reset from 'styled-reset';

export const GlobalStyle = createGlobalStyle`
  ${reset}

  * {
    font-family: 'Helvetica', sans-serif !important;
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  /* Marker CSS */
  .marker {
    & > .point {
      border: 4px solid ${MainColor[500]};
      border-radius: 100%;
      background-color: white;
      background-image: url('/lingtea-mini-icon.png');
      background-repeat: no-repeat;
      background-position: center;
      width: 30px;
      height: 30px;
      transition: all 0.2s ease-in-out;
    }
    & > .title {
      opacity: 0;
      position: absolute;
      border: 3px solid ${SubColor[300]};
      border-radius: 4px;
      padding: 4px 8px;
      background-color: white;
      font-size: 18px;
      font-weight: bold;
      transform: translate(calc(-50% + 15px), calc(-100% - 30px - 4px));
      transition: all 0.2s ease-in-out;
    }

    ${
      isMobile
        ? css`
            & > .title {
              display: none;
            }
          `
        : css`
            &:hover {
              & > .point {
                transform: scale(1.2);
              }
              & > .title {
                opacity: 1;
              }
            }
          `
    }
  }

  /* Information CSS */
  .information {
    padding: 8px;
    border: 2px solid ${SubColor[300]};
    border-radius: 4px;
    background-color: white;
    z-index: 100;
    display: block;
    transform: translate(-50%, 18px);
    transition: all 0.2s ease-in-out;
    width: 400px;
    white-space: normal;
    word-break: keep-all;
    & > .title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 4px;
    }
    & > .address {
      font-size: 16px;
      margin-bottom: 6px;
      & > span {
        margin-left: 4px;
        font-size: 14px;
        font-weight: bold;
        color: ${SubColor[400]};
        text-decoration: underline;
        cursor: pointer;
        &:hover {
          color: ${SubColor[500]};
        }
      }
    }
    & > .phone {
      color: ${MainColor[600]};
      font-size: 18px;
      font-weight: bold;
    }

    @media (max-width: 690px) {
      width: calc(100vw - 16px);
    }
  }
`;
