import { MainColor } from '@utils/color';
import styled, { css } from 'styled-components';

const List = styled.ul<{ panelHeight: number; visible: boolean }>`
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(100% - 140px);
  z-index: 10;
  @media (max-width: 767px) {
    position: fixed;
    border: 1px solid #eee;
    top: calc(${({ panelHeight }) => panelHeight}px + 4px + 120px);
    left: 8px;
    right: 8px;
    padding: 8px;
    background-color: white;
    max-height: 290px;
    border-radius: 6px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    display: ${({ visible }) => (visible ? 'block' : 'none')};
  }
`;
export default List;

export const ListItem = styled.li<{ selected?: boolean }>`
  cursor: pointer;
  border-radius: 5px;
  padding: 10px;
  transition: all 0.3s;
  background-color: ${({ selected = false }) =>
    selected ? MainColor[200] : css`#fff`};
  .title {
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
  }
  .address {
    word-break: keep-all;
    font-size: 18px;
    line-height: 24px;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .distance {
    font-size: 14px;
    color: #999;
  }
`;
