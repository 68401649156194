import { LingteaData } from '@utils/api';

export const generateMarker = (map: any, datum: LingteaData) => {
  const marker = new kakao.maps.CustomOverlay({
    position: new kakao.maps.LatLng(datum.lat, datum.lng),
    content: `
      <a 
        onclick="window.markerClick('${datum.name}', ${datum.lat}, ${datum.lng}, '${datum.address}', '${datum.phone}')"
        class="marker">
        <div class="point"></div>
        <div class="title">${datum.name}</div>
      </a>
    `,
  });
  return marker;
};

export const generateBuildingMarker = (lat: number, lng: number) => {
  const marker = new kakao.maps.Marker({
    position: new kakao.maps.LatLng(lat, lng),
  });
  return marker;
};

export const generateInformation = (datum: LingteaData) => {
  const information = new kakao.maps.CustomOverlay({
    position: new kakao.maps.LatLng(datum.lat, datum.lng),
    content: `
      <div class="information">
        <div class="title">${datum.name}</div>
        <div class="address">${datum.address}<span onclick="copyToClipboard('${datum.address}')">복사</span></div>
        <a class="phone" href="tel:${datum.phone}">${datum.phone}</a>
      </div>
    `,
    xAnchor: 0,
    yAnchor: 0,
    zIndex: 1000,
    clickable: true,
  });
  return information;
};

export const { kakao } = window as any;
