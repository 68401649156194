import { getLingteaData, LingteaData } from '@utils/api';
import { API } from 'aws-amplify';
import React, { useCallback, useEffect, useState } from 'react';
import { AdminForm } from './index.style';

const AdminPage = () => {
  const [state, setState] = useState({
    data: undefined,
    key: '',
    error: undefined as any | undefined,
    valid: false,
    loading: false,
    submitting: false,
    lingteaData: [] as LingteaData[],
  });

  const changeHandler: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const file = e?.target?.files?.item(0);
      if (!file) return;
      const reader = new FileReader();
      reader.readAsText(file, 'euc-kr');
      reader.onload = async (e) => {
        const data = (e.target as any).result;
        setState((state) => ({
          ...state,
          data,
          error: undefined,
          valid: false,
          loading: false,
          lingteaData: [],
        }));
      };
    },
    [],
  );

  const inputHandler: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const { name, value } = e.target;
      setState((state) => ({
        ...state,
        [name]: value,
      }));
    },
    [],
  );

  const submitHandler: React.FormEventHandler<HTMLFormElement> = useCallback(
    async (e) => {
      e.preventDefault();
      setState((state) => ({ ...state, error: undefined, submitting: true }));
      try {
        const result = await API.put('lingteaMaps', '/pharmacies', {
          body: { data: state.data, key: state.key },
        });
        window.alert(result.message || '업데이트 완료!');
      } catch (error: any) {
        console.log('error', { ...error });
        setState((state) => ({
          ...state,
          error: error?.response?.data?.message ?? error?.message,
        }));
      } finally {
        setState((state) => ({ ...state, submitting: false }));
      }
    },
    [state.data, state.key],
  );

  useEffect(() => {
    if (!state.data) return;
    setState((state) => ({
      ...state,
      loading: true,
    }));
    const validate = async () => {
      try {
        const lingteaData = await getLingteaData(state.data);
        const uniqueLingteaData = lingteaData.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                t.name === item.name &&
                t.address === item.address &&
                t.phone === item.phone &&
                t.lat === item.lat &&
                t.lng === item.lng,
            ),
        );
        setState((state) => ({
          ...state,
          valid: true,
          lingteaData: uniqueLingteaData,
        }));
      } catch (error: any) {
        setState((state) => ({
          ...state,
          error: error.message,
          valid: false,
        }));
      } finally {
        setState((state) => ({ ...state, loading: false }));
      }
    };
    validate();
  }, [state.data]);

  return (
    <AdminForm onSubmit={submitHandler}>
      <h1>어드민 페이지</h1>
      <h2>
        CSV 파일 선택 후 &rdquo;등록&rdquo; 버튼을 누르면 새로운 약국 정보로
        반영됩니다.
      </h2>
      <label htmlFor="file">
        업로드 파일 선택:
        <input
          id="file"
          type="file"
          accept="text/csv"
          onChange={changeHandler}
          disabled={state.loading}
        />
      </label>
      <label htmlFor="key">
        관리자 비밀번호 입력:
        <input
          id="key"
          type="password"
          name="key"
          value={state.key}
          onInput={inputHandler}
          disabled={state.loading}
        />
      </label>
      <button
        type="submit"
        disabled={state.submitting || !state.valid || state.key.length === 0}
      >
        등록
      </button>
      <hr />
      {state.loading && <div>CSV 파일 분석 중...</div>}
      {state.submitting && <div>업데이트 중...</div>}
      {state.error && <p>{state.error}</p>}
    </AdminForm>
  );
};

export default AdminPage;
