import styled from 'styled-components';

export const SearchController = styled.form`
  margin: 8px 0px;

  input {
    width: 70%;
    padding: 8px;
    margin: 0;
    border: none;
    outline: none;
    border-bottom: 1px solid #ededed;
    font-size: 18px;
    font-weight: 700;
    &::placeholder {
      color: gray;
      font-size: 14px;
    }
  }

  button {
    cursor: pointer;
    width: 30%;
    padding: 8px;
    margin: 0;
    border: none;
    outline: none;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    background-color: #33a3ff;
    &:hover {
      background-color: #008cff;
    }
  }
`;
