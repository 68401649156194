import 'react-app-polyfill/ie9'; // Must be in first line
import 'react-app-polyfill/stable'; // Must be in second line
// ------------------------------------------------------------
import Amplify from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import awsconfig from './aws-exports.js';

Amplify.configure(awsconfig);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
