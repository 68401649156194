export const isIE = /* @cc_on!@ */ false || !!(document as any).documentMode;
export const isEdge =
  /* @cc_on!@ */ false || !!(navigator as any).userAgent.match(/Edge/);
export const isSafari = /^((?!chrome|android).)*safari/i.test(
  navigator.userAgent,
);
export const isFirefox = typeof (window as any)?.InstallTrigger !== 'undefined';
export const isChrome = !!(navigator as any).webdriver;
export const isOpera =
  !!(window as any)?.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
export const isPhantomJS = /PhantomJS/i.test(navigator.userAgent);
export const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );
export const isIframe = (window as any)?.self !== (window as any)?.window;
export const isNode =
  typeof process !== 'undefined' && process.versions && process.versions.node;
export const isIE9 =
  isIE && ((window as any)?.document as any)?.documentMode === 9;
export const isAndroid = /Android/i.test(navigator.userAgent);
export const isIOS = /\b(iPad|iPhone|iPod)\b/i.test(navigator.userAgent);
export const isMobileOpera = /Opera Mini/i.test(navigator.userAgent);
export const isChromeMobile = /(CriOS|Chrome)(?=\/)/i.test(navigator.userAgent);
export const isFirefoxMobile = isFirefox && isMobile;
export const isPhantomJSMobile = isPhantomJS && isMobile;
export const isDesktopSafari =
  !isIOS &&
  !isChromeMobile &&
  !isPhantomJSMobile &&
  /safari/i.test(navigator.userAgent);
export const isChromeDesktop = isChrome && !isMobile && !isChromeMobile;
export const isIE10 =
  isIE && ((window as any)?.document as any)?.documentMode === 10;
export const isIE11 =
  isIE && ((window as any)?.document as any)?.documentMode === 11;
