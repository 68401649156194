import styled from 'styled-components';

export const RegionController = styled.div`
  margin: 8px 0px;
  select {
    cursor: pointer;
    width: calc(50% - 8px);
    padding: 8px;
    margin: 0px 4px;
    border: none;
    border-radius: 4px;
    outline: none;
    font-size: 18px;
    font-weight: 700;
    background-color: #ededed;
    &:hover {
      background-color: #e6e6e6;
    }
  }
`;

export const CancelButton = styled.button`
  cursor: pointer;
  width: 100%;
  padding: 8px;
  margin: 0;
  margin-bottom: 8px;
  border: none;
  border-radius: 8px;
  outline: none;
  font-size: 18px;
  font-weight: 700;
  color: white;
  background-color: #e60000;
  &:hover {
    background-color: #ca0101;
  }
`;
