import styled from 'styled-components';

export const AdminForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 16px;
  & > * {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  & > h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
  }
  & > h2 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 16px;
  }
  & > label {
    display: flex;
    flex-direction: row;

    &:first-of-type {
      flex-direction: column;
    }
  }
  & > button {
    margin-top: 16px;
    align-self: flex-start;
    width: 100px;
    font-size: 18px;
  }
  & > hr {
    margin: 16px 0;
  }
`;
