import { searchKeyword } from '@utils/api';
import React, { useState } from 'react';
import List, { ListItem } from './List';
import { SearchController } from './SearchTab.style';

interface SearchTabProps {
  panelHeight: number;
  setMapCenter: (lat: number, lng: number) => void;
  setBuildingMarker: (lat: number, lng: number) => void;
}

type Building = {
  id: string;
  y: string;
  x: string;
  place_name: string;
  distance: string;
  road_address_name: string;
  address_name: string;
};

const SearchTab: React.FC<SearchTabProps> = (props: SearchTabProps) => {
  const [term, setTerm] = useState<string>('');
  const [buildings, setBuildings] = useState<Building[]>([]);
  const [listVisible, setListVisible] = useState<boolean>(false);
  const { panelHeight, setMapCenter, setBuildingMarker } = props;

  const search = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const {
        data: { documents: kakaoPlaces },
      } = await searchKeyword(term);
      setBuildings(
        kakaoPlaces.map(
          // prettier-ignore
          ({ id, y, x, place_name, distance, road_address_name, address_name, }) =>
           ({ id, y, x, place_name, distance, road_address_name, address_name, }),
        ),
      );
      setListVisible(true);
    } catch (error) {
      console.log(error);
    }
  };

  const selectBuilding = (y: string, x: string) => () => {
    setMapCenter(+y, +x);
    setListVisible(false);
    setBuildingMarker(+y, +x);
  };

  return (
    <>
      {/* 검색 컨트롤러 */}
      <SearchController onSubmit={search}>
        <input
          type="text"
          placeholder="주소나 장소명을 입력해주세요."
          value={term}
          onChange={(e) => setTerm(e.target.value)}
          onFocus={() => setListVisible(true)}
        />
        <button type="submit">검색</button>
      </SearchController>

      {/* 검색 결과 */}
      <List
        panelHeight={panelHeight}
        visible={listVisible && buildings.length > 0}
      >
        {buildings.map((building) => (
          <ListItem
            key={building.id}
            onClick={selectBuilding(building.y, building.x)}
          >
            <div className="title">{building.place_name}</div>
            <div className="address">
              {building.road_address_name || building.address_name}
            </div>
            {building.distance?.length > 0 && (
              <div className="distance">{building.distance}km</div>
            )}
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default SearchTab;
