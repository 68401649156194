import { debounce } from '@utils';
import { calculateNearestLingteaData, LingteaData } from '@utils/api';
import { INITIAL_KAKAO_MAP_LEVEL } from '@utils/constant';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import {
  generateBuildingMarker,
  generateInformation,
  generateMarker,
  kakao,
} from './index.kakaomap';

export const useKakaoMap = () => {
  const [state, setState] = useState({
    data: [] as LingteaData[],
    selectedData: undefined as LingteaData | undefined,
    displayingData: [] as LingteaData[],
    center: { lat: 37.56699, lng: 126.97838 },
    level: 3 as number,
    markers: [] as any[],
    $map: null as any,
    $information: undefined as any,
    $buildingMarker: undefined as any,
    $zoomControl: null as any,
  });

  // 지도 DOM, State 초기작업
  const mapRef = useCallback<React.RefCallback<HTMLDivElement>>(
    ($el: HTMLDivElement) => {
      if (!$el) return;
      // Map 생성
      const options = {
        center: new kakao.maps.LatLng(37.365264512305174, 127.10676860117488),
        level: INITIAL_KAKAO_MAP_LEVEL,
      };
      const $map = new kakao.maps.Map($el, options);
      kakao.maps.event.addListener($map, 'bounds_changed', () => {
        const latLng = $map.getCenter();
        const level = $map.getLevel();
        setState((state) => ({
          ...state,
          center: { lat: +latLng.getLat(), lng: +latLng.getLng() },
          level,
        }));
      });
      kakao.maps.event.addListener($map, 'click', () => {
        setState((state) => ({
          ...state,
          selectedData: undefined,
        }));
      });
      setState((state) => ({ ...state, $map }));

      // Zoom Control 생성
      const $zoomControl = new kakao.maps.ZoomControl();
      $map.addControl($zoomControl, kakao.maps.ControlPosition.RIGHT);
      setState((state) => ({ ...state, $zoomControl }));

      const $buildingMarker = generateBuildingMarker(0, 0);
      setState((state) => ({ ...state, $buildingMarker }));
    },
    [],
  );

  // Selected Lingtea Data 가 업데이트되면 지도상에 다시 나타낸다.
  useLayoutEffect(() => {
    if (!state.$map) return;
    if (state?.$information?.setMap) state.$information.setMap(null);
    if (typeof state.selectedData === 'undefined') {
      setState((state) => ({ ...state, $information: undefined }));
      return;
    }
    state.$map.panTo(
      new kakao.maps.LatLng(state.selectedData.lat, state.selectedData.lng),
    );
    const $information = generateInformation(state.selectedData);
    $information.setMap(state.$map);
    setState((state) => ({ ...state, $information }));
  }, [state.selectedData]);

  // Hook 함수
  const setLingteaData = useCallback((data: LingteaData[]) => {
    setState((state) => ({ ...state, data, displayingData: data }));
  }, []);

  const setDisplayingLingteaData = useCallback((data: LingteaData[]) => {
    setState((state) => ({ ...state, displayingData: data }));
  }, []);

  const setSelectedLingteaData = useCallback((data?: LingteaData) => {
    setState((state) => {
      if (state.level > 5) {
        state.$map.setLevel(5);
      }
      return {
        ...state,
        level: state.level > 5 ? 5 : state.level,
        selectedData: data,
      };
    });
  }, []);

  const setBuildingMarker = useCallback(
    (lat: number, lng: number) => {
      if (!state.$buildingMarker) return;
      const latLng = new kakao.maps.LatLng(lat, lng);
      state.$buildingMarker.setPosition(latLng);
      state.$buildingMarker.setMap(state.$map);
    },
    [state],
  );

  /** 함수 실행으로 지도의 중심지를 설정한다
   * @param {number} lat - 위도
   * @param {number} lng - 경도
   */
  const setMapCenter = useCallback(
    (lat: number, lng: number) => {
      if (!state.$map) return;
      state.$map.setCenter(new kakao.maps.LatLng(lat, lng));
      state.$map.setLevel(INITIAL_KAKAO_MAP_LEVEL);
    },
    [state.$map],
  );

  /** RegionTab에서 시/군/구를 선택했을때 호출될 함수,
   * 여기에 해당하는 약국을 필터링해야한다.
   * @param {string} firstRegion - 대분류 지역
   * @param {string} secondRegion - 소분류 지역
   */
  const setRegion = useCallback(
    (firstRegion?: string, secondRegion?: string) => {
      if (!firstRegion || !secondRegion) {
        setDisplayingLingteaData(state.data);
        return;
      }
      const filteredLingteaData = state.data.filter(
        (each) =>
          each.address.includes(firstRegion) &&
          each.address.includes(` ${secondRegion} `),
      );
      const bounds = new kakao.maps.LatLngBounds();
      filteredLingteaData.forEach((each) => {
        bounds.extend(new kakao.maps.LatLng(each.lat, each.lng));
      });
      if (filteredLingteaData?.length > 1) {
        state.$map.setBounds(bounds);
      } else if (filteredLingteaData?.length === 1) {
        state.$map.setCenter(
          new kakao.maps.LatLng(
            filteredLingteaData[0].lat,
            filteredLingteaData[0].lng,
          ),
        );
      }
      setDisplayingLingteaData(filteredLingteaData);
    },
    [state.data, state.$map],
  );

  // displayingData 갱신
  const updateDisplayingData = useMemo(
    () =>
      debounce(() => {
        if (!state.$map || !(state.displayingData.length > 0)) return;
        const lingteaData = calculateNearestLingteaData(
          state.displayingData,
          state.$map.getCenter(),
          state.$map.getBounds(),
        ).filter((each) => !!each);
        const markers = lingteaData.map((datum) =>
          generateMarker(state.$map, datum),
        );
        setState((prevState) => {
          prevState.markers.forEach((marker) => marker.setMap(null));
          markers.forEach((map) => map.setMap(state.$map));
          return { ...prevState, markers };
        });
      }, 250),
    [state.$map, state.displayingData],
  );
  useEffect(
    () => updateDisplayingData(),
    [updateDisplayingData, state.center, state.level],
  );

  // level 높아지면 선택한 약국 선택해제
  useEffect(() => {
    if (state.level > 5) {
      if (state.selectedData) setSelectedLingteaData(undefined);
      if (state.$buildingMarker?.setMap) state.$buildingMarker.setMap(null);
    }
  }, [state.level, state.selectedData, setSelectedLingteaData]);

  return {
    mapRef,
    map: state.$map,
    lingteaData: state.data,
    displayingLingteaData: state.displayingData,
    selectedLingteaData: state.selectedData,
    setLingteaData,
    setDisplayingLingteaData,
    setSelectedLingteaData,
    setMapCenter,
    setRegion,
    setBuildingMarker,
  };
};
