import { LingteaData } from '@utils/api';
import { RegionData, RegionDataType } from '@utils/constant';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import List, { ListItem } from './List';
import { CancelButton, RegionController } from './RegionTab.style';

interface RegionTabProps {
  panelHeight: number;
  setMapCenter: (lat: number, lng: number) => void;
  setRegion: (firstRegion: string, secondRegion: string) => void;
  displayingData: LingteaData[];
  selectedLingteaData?: LingteaData;
  setSelectedLingteaData: (data?: LingteaData) => void;
}

const RegionTab: React.FC<RegionTabProps> = (props) => {
  const {
    panelHeight,
    setMapCenter,
    setRegion: _setRegion,
    displayingData,
    selectedLingteaData,
    setSelectedLingteaData,
  } = props;
  const [listVisible, setListVisible] = useState<boolean>(false);
  const [regions, setRegions] = React.useState({
    first: '',
    second: '',
  });
  const setRegion = useCallback(
    (firstRegion: string, secondRegion: string) => {
      _setRegion(firstRegion, secondRegion);
      setListVisible(true);
    },
    [_setRegion],
  );

  const firstRegions = useMemo(
    () => [
      '경기도',
      '서울특별시',
      '부산광역시',
      '충청북도',
      '경상남도',
      '대구광역시',
      '경상북도',
      '충청남도',
      '인천광역시',
      '전라북도',
      '광주광역시',
      '대전광역시',
      '강원도',
      '전라남도',
      '울산광역시',
      '제주시',
    ],
    [],
  );
  const secondRegions = useMemo(() => {
    if (!regions.first || regions.first.length === 0) return [];
    const result = RegionData[regions.first as keyof RegionDataType] || [];
    return result.sort((a, b) => a.localeCompare(b));
  }, [regions.first]);

  const selectPharmacy = useCallback(
    (datum: LingteaData) => () => {
      setMapCenter(+datum.lat, +datum.lng);
      setListVisible(false);
      setSelectedLingteaData(datum);
    },
    [setSelectedLingteaData, setMapCenter, setListVisible],
  );

  const cancelFiltering = useCallback(() => {
    setListVisible(false);
    setRegion('', '');
    setRegions({
      first: '',
      second: '',
    });
  }, [setSelectedLingteaData, setListVisible, setRegion]);

  useEffect(() => {
    if (regions.first.length > 0 && regions.second.length > 0) {
      setRegion(regions.first, regions.second);
    }
  }, [regions.first, regions.second, setRegion]);

  return (
    <>
      <RegionController>
        <select
          value={regions.first}
          onChange={(e) => setRegions({ first: e.target.value, second: '' })}
        >
          <option value="">도/시 선택</option>
          {deduplicate(firstRegions).map((region) => (
            <option value={region} key={region}>
              {region}
            </option>
          ))}
        </select>

        <select
          value={regions.second}
          onChange={(e) =>
            setRegions((regions) => ({ ...regions, second: e.target.value }))
          }
        >
          <option value="">시/군/구 선택</option>
          {deduplicate(secondRegions).map((region) => (
            <option value={region} key={region}>
              {region}
            </option>
          ))}
        </select>
      </RegionController>

      {regions.first.length !== 0 && regions.second.length !== 0 && (
        <>
          <CancelButton onClick={cancelFiltering}>
            시군구 설정 초기화
          </CancelButton>

          {/* 검색 결과 */}
          <List
            panelHeight={panelHeight}
            visible={listVisible && displayingData.length > 0}
          >
            {displayingData.slice(0, 100).map((displayDatum) => (
              <ListItem
                key={`${displayDatum.name}-${displayDatum.lat}-${displayDatum.lng}`}
                onClick={selectPharmacy(displayDatum)}
                selected={
                  selectedLingteaData &&
                  selectedLingteaData.name === displayDatum.name
                }
              >
                <div className="title">{displayDatum.name}</div>
                <div className="address">{displayDatum.address}</div>
                <div className="distance">{displayDatum.distance}</div>
              </ListItem>
            ))}
          </List>
        </>
      )}
    </>
  );
};

export default RegionTab;

const deduplicate = (array: any[]) => {
  const result: any[] = [];
  array.forEach((item) => {
    if (result.indexOf(item) === -1) {
      result.push(item);
    }
  });
  return result;
};
